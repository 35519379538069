const initIframeSize = () => {
  const iframeWrapper = document.querySelector( '.bs-div--common-form-wrapper' );
  const iframe = iframeWrapper.querySelector( 'iframe' );

  window.addEventListener( 'message', ( event ) => {
    if ( event.origin === 'https://info.unqork.com' && event.data ) {
      const iframeHeight = event.data;
      iframe.style.height = `${iframeHeight}px`;
      iframeWrapper.style.height = `${iframeHeight}px`;
    }
  } );
};

document.addEventListener( 'DOMContentLoaded', initIframeSize );
